@font-face {
    font-family: ccmiInterRegular;
    src: url("/public/fonts/Inter-Regular.ttf") format("opentype");
    font-display: swap;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    height: 100%;
    font-weight: 300 !important;
    line-height: 2 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.link {
    cursor:pointer !important;
    text-decoration: none !important;
}

:root {
    --final-degree: 135deg;
    --ccmi-bronze: #DDA752;
    --ccmi-silver: #959595;
    --ccmi-palegrey: #EEEEEE;
    --ccmi-gold: #D8C46F;
    --ccmi-paleblue: #EAF7FF;
    --ccmi-softblue: #7A8D96;
    --ccmi-lightblue: #0082CA;
    --ccmi-blue: #106DA0;
    --ccmi-darkblue: #065683;
    --ccmi-deepblue: #084864;
    --ccmi-red: #C11B20;
    --ccmi-pink: #F478C4;
    --ccmi-purple: #773DBD;
    --ccmi-green: #93d500;
    --ccmi-lightgreen: #52CB3C;
    --ccmi-membergreen: #2a6220;
    --ccmi-orange: #F08019;
}


.side-navigation-container {
    width: 400px;
    position: fixed;
    height: 100%;
    display: flex;
    background: var(--ccmi-darkblue);
}

.side-navigation-content-container {
    margin-top: 20px;
    padding: 120px 40px;
    font-size: 16px;
    width: calc(100% - 8px);
}

.sidenav-links-container {
    margin-top: 40px;
    padding-left: 30px;
}

.ccmi-logo-sidenav {
    width: 70%;
}

.sidenav-link {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 15px;
    color: white;
    display: block;
    width: fit-content;
    font-weight: 600;
}

.content-container {
    width: 100%;
    padding: 20px 20px 20px 420px;
}

.vertical-colour-bar-container {
    width: 8px;
    height: 100%;
}

.vertical-colour-bar {
    height: calc(100% / 12);
    width: 100%
}

.vertical-colour-bar:nth-of-type(7n+1) {
    background-color: var(--ccmi-lightblue);
}
.vertical-colour-bar:nth-of-type(7n+2) {
    background-color: var(--ccmi-red);
}
.vertical-colour-bar:nth-of-type(7n+3) {
    background-color: #F08019;
}
.vertical-colour-bar:nth-of-type(7n+4) {
    background-color: #93D500;
}
.vertical-colour-bar:nth-of-type(7n+5) {
    background-color: var(--ccmi-purple);
}
.vertical-colour-bar:nth-of-type(7n+6) {
    background-color: #F478C4;
}
.vertical-colour-bar:nth-of-type(7n+7) {
    background-color: #FFDD00;
}

.user-login-header {
    position: absolute;
    right: 0;
    top: 10px;
}

.user-details {
    display: flex;
}

.logged-in-user {
    padding: 10px;
}

.button.logout {
    margin: 10px;
}

.button {
    background-color: var(--ccmi-lightblue);
    color: white;
    border: none;
    border-radius: 30px;
    display: flex;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: fit-content;
    padding: 10px 30px;
    font-weight: 500;
}

.button.grey {
    background: #7A8D96 !important;
}

.page-title {
    font-size: 30px;
    font-weight: 700;
}

.home.page-title {
    margin-top: 20%;
    margin-left: 40px;
}

.home-page-description {
    margin-left: 40px;
}

.table-container {
    width: 90%;
    margin: 20px 40px;
}

.table-row {
    display: flex;
}

.table-column.wide {
    width: 200%;
}

.table-column.narrow {
    width: 50%;
}

.table-column {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--ccmi-lightblue);
    border-left: 1px solid var(--ccmi-lightblue);
    height: 84px;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table.button {
    margin-left: auto;
    margin-right: auto;}

.table-column.last {
    border-right: 1px solid var(--ccmi-lightblue);
}

.table-column.heading {
    border-top: 1px solid var(--ccmi-lightblue);
    background: var(--ccmi-deepblue);
    color: white;
    text-align: center;
}

.table-navigation-button-container {
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.table-pages {
    display: flex;
}

.table-pages .page {
    width: 30px;
    padding-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    font-size: 12px;
}

.table-pages .page.active {
    background: var(--ccmi-lightblue);
    color: white;
    border-radius: 10px;
    font-size: 12px;
}

.table-right.button,
.table-left.button {
    background: var(--ccmi-deepblue);
}

.page-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    cursor: default;
    background: rgba(0, 0, 0, 0.5);
}

.dialog-container {
    background-color: white;
    position: fixed;
    top: 20%;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 400px;
    min-height: 200px;
    z-index: 100;
    border-radius: 10px;
    color: #282c34;
    border: solid 1px #E0E3E8;
}

.dialog-header {
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
}

.dialog-text {
    padding: 20px;
    font-size: 16px;
}

.dialog-footer-container {
    display: flex;
}

.dialog-button-text {
    padding: 5px 30px;
}

.dialog-button-text.link {
    color: white;
    padding-top: 8px;
}

.dialog.button {
    margin: 20px auto;
}

.insert-new.button {
    margin-top: 60px;
    margin-left: 10px;
}

.form-container {
    width: 400px;
}

.generic-label-container,
.generic-input-container {
    margin-top: 10px;
}

.input-container textarea,
.input-container input {
    border: solid 1px #9DD6F7;
    background: #FAFDFF;
    width: 100%;
    padding: 5px 15px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    color: black;
    fontFamily: "ccmiInterMedium"
}

.label,
.input-label {
    font-size: 13px;
    font-weight: 600;
    color: var(--ccmi-lightblue);
}

.search-users-container {
    width: 100%;
    display: flex;
}

.user-search-field-container {
    width: 30%;
    margin: 1.5%;
}

.reset-button {
    margin-top: 2px;
    margin-left: auto;
    width: fit-content;
    font-size: 12px;
    color: var(--ccmi-lightblue);
    height: 22px;
    border-bottom: 1px solid var(--ccmi-lightblue);
}

.page-subtitle {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}

.page-sub-subtitle {
    margin-top: 20px;
    font-size: 17px;
    font-weight: 700;
}

.user-membership-modify.button {
    margin-top: 20px;
}

.modify-subscription-button-container {
    display: flex;
}

.user-membership-modify.submit {
    margin-right: 20px;
}

.note {
    margin: 10px 4px;
    font-size: 12px;
    line-height: 12px;
}

.note span {
    color: #0082CA;
    font-size: 15px;
    font-weight: 900;
}

b {
    font-weight: 800;
}

.column-container {
    display: flex;
    overflow: scroll;
}

.users-profile-picture-container {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    margin-right: 10px
}

.users-info-container {
    text-align: left;
}

.subscription-container {
    margin: 20px 10px;
    background: grey;
    color: black;
    border-radius: 15px;
}

.subscription-container.bronze {
    background: var(--ccmi-bronze);
}
.subscription-container.silver {
    background: var(--ccmi-silver);
}
.subscription-container.gold {
    background: var(--ccmi-gold);
}

.verification-container {
    margin: 20px 10px;
    background: red;
    color: white;
    border-radius: 5px;
}

.verification-container.verified {
    background: green;
}

.verification-container.bronze {
    background: grey;
}

.registered-container,
.edit-button-container,
.country-container {
    padding-top: 20px;
}

.profile-image-circle.default {
    border: solid 1px var(--ccmi-lightblue);
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image-circle.default img {
    width: 38px;
    height: 50px;
    object-fit: none;
    border-radius: 0;
    border: none;
}

.profile-image-circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #E0F4FF;
}

.profile-image-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    border: 1px solid #9DD6F7;
}


.form-subtitle {
    margin-top: 20px;
    font-size: 16px;
    color: black;
}

.form-note.error {
    color: red;
}

.form-container.flex {
    display: flex;
    justify-content: space-between;
}

.spacer {
    width: 100%;
    height: 100px;
}

.note.comment {
    font-size: 16px;
}

.note-info-container {
    display: flex;
}

.note.bold {
    font-weight: 800;
}

.notes-form-container {
    min-width: 400px;
    width: 40%;
    height: fit-content;
}

.note.comment {
    line-height: 16px;
}

.user-note {
    margin-top: 10px;
    margin-bottom: 10px;
}

.notes-form-container textarea {
    height: fit-content;
    min-height: 120px;
}

.table-dropdown-button-container {
    width: 100%;
    display: flex;
    justify-content: right;
}

.table-dropdown-button {
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid black;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-column.heading {
    height: 90px;
    margin: auto auto;
}

.table-dropdown-container {
    width: 100%;
    border: 1px solid black;
    background-color: #FFFFFF;
    position: relative;
    color: black;
    height: 60px;
    font-family: ccmiInterRegular;
    overflow-y: auto;
}

.table-dropdown-row {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid black;
}

.table-dropdown-row.first {
    border: none;
}

.table-dropdown-row:hover {
    background-color: var(--ccmi-lightblue);
    color: #FFFFFF;
}

.user-search-filters-container {
    display: flex;
    justify-content: start;
    align-items: center;
    height: 40px;
    margin: 0px 40px;
}

.user-selected-filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ccmi-darkblue);
    border: 1px solid var(--ccmi-blue);
    height: 100%;
    padding: 5px 10px;
    margin-right: 20px;
    font-family: ccmiInterRegular;
    color: #FFFFFF;
}

.user-selected-filter-container.hide {
    display: none;
}

.user-selected-filter-cross-container {
    margin-right: 5px;
}